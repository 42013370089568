import { IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useComparativeFiltersTranslation } from '../../hooks/useComparativeFiltersTranslation';
import './ComparativeFilters.scss';

export interface FilterModel {
    filter: any;
    filterType: any;
    type: any;
}

type ComparativeFiltersProps = {
    filtersObject: FilterModel | undefined;
    updateFilters: (newFilters) => void;
};

const ComparativeFilters = ({ filtersObject, updateFilters }: ComparativeFiltersProps) => {
    const { getFilterTranslation } = useComparativeFiltersTranslation();
    const [filters, setFilters] = useState<FilterModel | undefined>(filtersObject);

    const removeFilterByKey = (key) => {
        const filtersCopy = JSON.parse(JSON.stringify(filters));
        delete filtersCopy[key];

        setFilters(filtersCopy);
        updateFilters(filtersCopy);
    };

    useEffect(() => {
        setFilters(filtersObject);
    }, [filtersObject]);

    return (
        <>
            {filters && Object.keys(filters).length > 0 && (
                <div className="ComparativeFilters">
                    Filtros aplicados:
                    {Object.keys(filters || {}).map((key, i) => (
                        <span key={i} className="ComparativeFilters__Filter" onClick={() => removeFilterByKey(key)}>
                            {getFilterTranslation(filters, key)}
                            <IconX />
                        </span>
                    ))}
                </div>
            )}
        </>
    );
};

export default ComparativeFilters;
