import { IconAlertOctagon, IconDownload } from '@tabler/icons';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';

import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

// from https://www.ag-grid.com/theme-builder/
// import './styles/ag-grid-theme-builder.css';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import EmptyState from 'components/BasicComponents/EmptyState';
import Spinner from 'components/BasicComponents/Spinner/Spinner';
import { useComparative } from 'modules/comparative';
import { useMemo, useRef } from 'react';
import './Comparative.scss';
import ComparativeExplanation from './components/ComparativeExplanation/ComparativeExplanation';
import ComparativeFilters from './components/ComparativeFilters';
import { FilterModel } from './components/ComparativeFilters/ComparativeFilters';
import ComparativeObservationsModal from './components/ComparativeObservationsModal/ComparativeObservationsModal';
import ComparativeUpdater from './components/ComparativeUpdater';
import { useComparativeExport } from './hooks/useComparativeExport';
import { useComparativeGrid } from './hooks/useComparativeGrid';
import { useComparativeHeight } from './hooks/useComparativeHeight';
import { useComparativeState } from './hooks/useComparativeState';

const Comparative = () => {
    const [t] = useTranslation('comparative');
    const comparative = useComparative();
    const container = useComparativeHeight();
    const {
        options,
        openExplanationModal,
        setOpenExplanationModal,
        openObservationsModal,
        setOpenObservationsModal,
        selectedObservationsInfo
    } = useComparativeGrid();
    const { save, state, initialState } = useComparativeState();

    const gridRef = useRef<AgGridReact>(null);
    const { exportAsCsv } = useComparativeExport({ gridRef });

    // Avoid re-renders when an useState is triggered (for example, when filters change)
    const agGrid = useMemo(
        () => (
            <AgGridReact
                ref={gridRef}
                initialState={initialState}
                rowData={comparative.data || []}
                onStateUpdated={(p) => save(p.state)}
                noRowsOverlayComponent={() => <Spinner />}
                getRowId={(row) => row.data.promotion.id}
                {...options}
            />
        ),
        [comparative?.data, initialState]
    );

    return (
        <div className="Comparative">
            <div
                ref={container.ref}
                className="ag-theme-quartz" // applying the Data Grid theme
            >
                {comparative.error ? (
                    <>
                        <ErrorCard error={comparative.error} />
                        <EmptyState icon={IconAlertOctagon} title={t('error.title')} subtitle={t('error.subtitle')} />
                    </>
                ) : (
                    <>
                        {/* Header ID is being used at useComparativeHeight */}
                        <header className="Comparative__header" id="Comparative__header">
                            <ComparativeFilters
                                filtersObject={(state.filter?.filterModel as FilterModel) || undefined}
                                updateFilters={(updatedFilters) => gridRef.current?.api?.setFilterModel(updatedFilters)}
                            />
                            <div className="Comparative__actions">
                                <ComparativeUpdater onRefresh={comparative.refetch} />
                                <SmallButton
                                    color={'blue'}
                                    titulo={t('exportCsv')}
                                    icon={<IconDownload />}
                                    action={() => exportAsCsv()}
                                />
                            </div>
                        </header>
                        {agGrid}
                        {openExplanationModal && (
                            <ComparativeExplanation
                                openModal={openExplanationModal}
                                setOpenModal={setOpenExplanationModal}
                            />
                        )}
                        {openObservationsModal && (
                            <ComparativeObservationsModal
                                openModal={openObservationsModal}
                                setOpenModal={setOpenObservationsModal}
                                observationsInfo={selectedObservationsInfo}
                                onRefresh={comparative.refetch}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Comparative;
