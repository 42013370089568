import { GridState } from 'ag-grid-community';
import { useState } from 'react';

const KEY = 'Comparative';

export function useComparativeState() {
    const restore = () => JSON.parse(sessionStorage.getItem(KEY) || '{}') as GridState;
    /* Why 2 states? 
        AgGrid component (in Comparative.tsx) re-renders if we use 'state' as its 'initialState'.
        So we use 'initialState' just to initiate that component and 'state' variable keeps updated the current values (used for filters, for example)
    */
    const [state, setState] = useState<GridState>(restore());
    const [initialState] = useState<GridState>(restore());

    const save = (payload: GridState) => {
        const { filter, sort, columnOrder, scroll, columnPinning, columnGroup, columnSizing, columnVisibility } =
            payload;

        //  improve navigation when entering/exiting a project
        sessionStorage.setItem(
            KEY,
            JSON.stringify({
                filter,
                sort,
                columnOrder,
                columnPinning,
                columnGroup,
                columnSizing,
                columnVisibility,
                scroll
            })
        );

        setState(restore());
    };

    return { initialState, state, save };
}
