import { IndexedDbCache } from 'lib/cache';
import { ComparativePromotion, ComparativeProvider, HttpComparativeApi } from 'modules/comparative';
import Comparative from './Comparative';

const cache = new IndexedDbCache<ComparativePromotion[]>({
    key: 'Comparative',
    expires: {
        every: 60 * 60, // every hour
        offset: 0 // o'clock
    }
});

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <ComparativeProvider api={new HttpComparativeApi(cache)} cache={cache}>
        <Comparative />
    </ComparativeProvider>
);
