import { useTranslation } from 'react-i18next';

import cocircularLogo from 'assets/logo-blue.png';
import { TituloH2, TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import { Traceability } from 'modules/traceabilities';
import { cambiarOrdenFecha, currencyFormatDE } from 'utils/helpers/general.helpers';
import './Di.scss';

interface DiProps {
    referent: any;
    diData: Traceability;
}

const Di = ({ referent, diData }: DiProps) => {
    const [t] = useTranslation();

    if (!diData) return null;

    return (
        <div ref={referent} className="di">
            <style>{'@page { margin: 2rem}'}</style>

            <header>
                <img src={cocircularLogo} alt="Logo CoCircular" className="di__logo" />
            </header>
            <div className="di__titleContainer" data-testid="DI__metadata">
                <TituloH2 titulo="Documento de identificación de residuos" />
                <p>
                    {diData.di.num} - {diData.date ? cambiarOrdenFecha(diData.date) : '-'}
                </p>
            </div>

            <hr />
            <div>
                <div>
                    <TituloH3 titulo={t('documentData', { ns: 'traceabilityE2E' })} />
                    <p>
                        {t('di.number', { ns: 'traceability' })}: <span>{diData.di.num}</span>
                    </p>
                    <p>
                        {t('date.transferStart', { ns: 'common' })}:{' '}
                        <span>{diData.date ? cambiarOrdenFecha(diData.date) : '-'}</span>
                    </p>
                </div>

                <div className="di__container">
                    <div data-testid="DI__transferOperator">
                        <TituloH3 titulo={t('transferOperator', { ns: 'traceabilityE2E' })} />
                        <p>
                            {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.transferOperator.name}</span>
                        </p>
                        <p>
                            {t('address', { ns: 'common' })}:
                            <span>
                                {diData.transferOperator.addresses?.[0]?.address} -{' '}
                                {diData.transferOperator.addresses?.[0]?.city.name} -{' '}
                                {diData.transferOperator.addresses?.[0]?.province.name}
                            </span>
                        </p>

                        <p>
                            CIF:
                            <span>{diData.transferOperator.nif}</span>
                        </p>
                        <p>
                            NIMA:
                            <span>{diData.transferOperator.nima}</span>
                        </p>
                    </div>
                    <div data-testid="DI__owner">
                        <TituloH3 titulo={t('wasteHolderInformation', { ns: 'traceabilityE2E' })} />
                        <p>
                            {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.project.enterpriseName}</span>
                        </p>
                        <p>
                            {t('address', { ns: 'common' })}:
                            <span>
                                {diData.project.address.address}, {diData.project.address.city.name},{' '}
                                {diData.project.address.province.name}
                            </span>
                        </p>
                        <p>
                            CIF:
                            <span>{diData.project.enterpriseCif || '-'}</span>
                        </p>
                        <p>
                            NIMA:<span>{diData.project.nima ? diData.project.nima : '-'}</span>
                        </p>
                    </div>
                </div>
                <div data-testid="DI__destination">
                    <TituloH3 titulo={t('destinationInformation', { ns: 'traceabilityE2E' })} />
                    <TituloH2 titulo={t('destinationPlantInformation', { ns: 'traceabilityE2E' })} />
                    <p>
                        {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.wasteManager.name}</span>
                    </p>
                    <p>
                        {t('address', { ns: 'common' })}:
                        <span>
                            {diData.wasteManager.addresses?.length > 0
                                ? diData.wasteManager.addresses[0] &&
                                  `${diData.wasteManager.addresses[0].address}, ${diData.wasteManager.addresses[0].city.name} (${diData.wasteManager.addresses[0].province.name})`
                                : '-'}
                        </span>
                    </p>
                    <p>
                        CIF:<span>{diData.wasteManager.nif}</span>
                    </p>
                    <p>
                        NIMA:<span>{diData.wasteManager.nima}</span>
                    </p>
                    <p>
                        {t('treatmentOperation', { ns: 'traceabilityE2E' })}:
                        <span>
                            {diData.process.valorizationCode} - {diData.process.description}
                        </span>
                    </p>

                    <TituloH2 titulo={t('authorizedWasteInformation', { ns: 'traceabilityE2E' })} />
                    <p>
                        {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.wasteManager.name}</span>
                    </p>
                    <p>
                        CIF:<span>{diData.wasteManager.nif}</span>
                    </p>
                    <p>
                        NIMA:<span>{diData.wasteManager.nima}</span>
                    </p>
                </div>
                <div className="di__container">
                    <div data-testid="DI__waste">
                        <TituloH3 titulo={t('wasteCharacteristics', { ns: 'common' })} />
                        <p>
                            {t('wasteCode', { ns: 'common' })}:<span>{diData.ler.code}</span>
                        </p>
                        <p>
                            {t('wasteDescription', { ns: 'common' })}:<span>{diData.ler.desc}</span>
                        </p>
                        <p>
                            {t('wasteAmount', { ns: 'common' })} (kg):
                            <span>{diData.weight ? currencyFormatDE(diData.weight * 1000) : ''}</span>
                        </p>
                    </div>

                    <div data-testid="DI__carrier">
                        <TituloH3 titulo={t('transporterInformation', { ns: 'traceabilityE2E' })} />
                        <p>
                            {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.carrier.name}</span>
                        </p>
                        <p>
                            CIF:<span>{diData.carrier.nif}</span>
                        </p>
                        <p>
                            NIMA:<span>{diData.carrier.nima}</span>
                        </p>
                    </div>
                </div>
                {diData.observations && (
                    <div data-testid="DI__observations">
                        <TituloH3 titulo={t('comments', { ns: 'common' })} />
                        <p>
                            <span>{diData.observations}</span>
                        </p>
                    </div>
                )}
                <div>
                    <TituloH3 titulo={t('acceptanceRejectionInformation', { ns: 'traceabilityE2E' })} />
                    <p>
                        {t('date.deliveryDate', { ns: 'common' })}:<span>{cambiarOrdenFecha(diData.date)}</span>
                    </p>
                    <p>
                        {t('amountReceived', { ns: 'traceabilityE2E' })} (kg):
                        <span>{diData.weight ? currencyFormatDE(diData.weight * 1000) : ''}</span>
                    </p>

                    {(diData.status === 'COMPLETED' || diData.status === 'WARNING') && (
                        <p>
                            {t('date.wasteAcceptance', { ns: 'common' })}:<span>{cambiarOrdenFecha(diData.date)}</span>
                        </p>
                    )}
                    {diData.status === 'DENIED' && (
                        <p>
                            {cambiarOrdenFecha(diData.date)}:<span>{cambiarOrdenFecha(diData.date)}</span>
                        </p>
                    )}
                </div>
            </div>

            <div className="di__singContainer">
                <div className="di__sign">
                    <h5>{t('diRequestedBy', { ns: 'traceabilityE2E' })}:</h5>
                    <p>
                        {diData.project.name} {t('theDay', { ns: 'common' })} {cambiarOrdenFecha(diData.date)}
                    </p>
                </div>
                <div className="di__sign">
                    <h5>{t('transportationAcceptedBy', { ns: 'traceabilityE2E' })}:</h5>
                    <p>{diData.carrier.name}</p>
                </div>
                <div className="di__sign">
                    <h5>{t('wasteAcceptedBy', { ns: 'traceabilityE2E' })}:</h5>
                    <p>{diData.status === 'COMPLETED' && diData.wasteManager.name}</p>
                </div>
            </div>
        </div>
    );
};

export default Di;
