import { AgGridReact } from 'ag-grid-react';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { RefObject } from 'react';
import { FilterModel } from '../components/ComparativeFilters/ComparativeFilters';
import { getLastUpdateDate } from '../helpers/comparative.helpers';
import { useComparativeFiltersTranslation } from './useComparativeFiltersTranslation';

interface UseComparativeExport {
    gridRef: RefObject<AgGridReact>;
}

export function useComparativeExport({ gridRef }: UseComparativeExport) {
    const { format } = useDateFormatter();
    const { getFilterTranslation } = useComparativeFiltersTranslation();

    const getFiltersString = () => {
        const rawFilters = gridRef.current?.api?.getFilterModel();

        if (!rawFilters || Object.keys(rawFilters)?.length === 0) return 'Ninguno';

        const filters = Object.keys(rawFilters).map((key) => getFilterTranslation(rawFilters as FilterModel, key));
        const filtersString = filters.join('\n');
        return filtersString;
    };

    const exportAsCsv = () => {
        const currentTime = format(new Date(), 'YYYY/MM/DD_HH:mm');
        const lastUpdate = format(getLastUpdateDate(), 'HH:mm');
        const filtersString = getFiltersString();

        if (!gridRef) return;

        gridRef.current?.api.exportDataAsCsv({
            allColumns: true,
            fileName: 'Comparativa--' + currentTime,
            prependContent: `Fecha y hora de descarga: ${currentTime} (última actualización de datos a las ${lastUpdate}). Filtros aplicados:\n${filtersString}\n`,
            processCellCallback: (params) => {
                const columnId = params.column.getId();

                // UPDATE IF DTO/TABLE CHANGES
                switch (columnId) {
                    case 'kpis.valorization':
                    case 'kpis.mix':
                        return params.value.value;
                    case 'kpis.mandatoryFlows':
                        return `${params.value.segregated}/${params.value.total}`;
                    case 'evaluation.documentation.lastTraceability':
                        return `${params.value.daysPassed}`;
                    case 'promotion.managers':
                    case 'promotion.tags':
                        return params.value?.map((entry) => entry?.name).join(', ');
                    default:
                        return params.value;
                }
            }
        });
    };

    return { exportAsCsv };
}
