import { useDateFormatter } from 'hooks/useDateFormatter';
import { Trans, useTranslation } from 'react-i18next';

import { IconInfoCircle } from '@tabler/icons';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { useComparativeCache } from 'modules/comparative';
import { useEffect } from 'react';
import './ComparativeUpdater.scss';
import { getLastUpdateDate, getNextUpdateDate } from '../../helpers/comparative.helpers';

interface ComparativeUpdaterProps {
    onRefresh: () => Promise<any>;
}

const ComparativeUpdater = (props: ComparativeUpdaterProps) => {
    const [t] = useTranslation('comparative');
    const cache = useComparativeCache();
    const formatter = useDateFormatter();
    const stale = cache.meta.data?.stale || false;

    const nextUpdate = formatter.format(getNextUpdateDate(), 'HH:mm');

    useEffect(() => {
        cache.meta.refetch(); // manually refetch it -> with setInterval(fn, delay) "The first execution happens after 'delay' milliseconds"
        const interval = setInterval(cache.meta.refetch, 30 * 1000);
        return () => clearInterval(interval);
    }, []);

    // If data is stale, refresh it
    useEffect(() => {
        if (stale) props.onRefresh();
    }, [stale]);

    return (
        <div className="ComparativeUpdater">
            {cache.meta.data && cache.meta.data.age !== Infinity && (
                <Tooltip className="ComparativeUpdater__nextUpdate" msg={t('nextUpdate') + nextUpdate + 'h'}>
                    <Trans
                        i18nKey={'comparative:updatedAt'}
                        values={{ time: formatter.fromNow(getLastUpdateDate()) }}
                        components={{ b: <strong /> }}
                    ></Trans>
                    <IconInfoCircle />
                </Tooltip>
            )}
        </div>
    );
};

export default ComparativeUpdater;
