import { AG_GRID_LOCALE_ES } from 'i18n/agGrid_ES';
import { useTranslation } from 'react-i18next';
import { FilterModel } from '../components/ComparativeFilters/ComparativeFilters';

export function useComparativeFiltersTranslation() {
    const [t] = useTranslation('comparative');

    const getFilterTranslation = (filters: FilterModel, key: string) => {
        const type = filters[key].type;
        const value = filters[key].filter;

        const string = t(key) + ' ' + AG_GRID_LOCALE_ES[type]?.toLowerCase() + ' ' + (value || '');
        return string;
    };

    return { getFilterTranslation };
}
