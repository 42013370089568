import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { OnboardingPopover } from 'features/onboarding';
import SupportWidget from 'features/support/widgets/SupportWidget';
import { WasteRegisterDiPage } from 'features/waste-register';
import AnonRoute from '../components/routes/AnonRoute';
import PrivateRoute from '../components/routes/PrivateRoute';
import ErrorBoundary from '../pages/Error/ErrorBoundary';
import { NotFound } from '../pages/NotFound/NotFound';
import { PRIVATE_ROUTES } from './AllPrivateRouter';
import { RUTAS_ANON } from './AnonRouter';
import AppBootstrap from './AppBootstrap/AppBootstrap';
import GlobalStore from './GlobalStore';

const AppRouter = () => {
    return (
        <GlobalStore>
            <Router>
                <ErrorBoundary>
                    <AppBootstrap>
                        <SupportWidget />
                        <OnboardingPopover />

                        <Switch>
                            {/* You can enter this route regardless of whether you are logged in or not */}
                            <Route component={WasteRegisterDiPage} path="/download-di/:wasteRegisterDiUuid" exact />

                            {RUTAS_ANON.map((route) => (
                                <AnonRoute
                                    exact={route.exact === undefined ? true : route.exact}
                                    path={route.path}
                                    component={route.component}
                                    key={route.path}
                                />
                            ))}

                            <Redirect from="/" to="/account" exact />

                            {PRIVATE_ROUTES.map((route: any) => (
                                <PrivateRoute
                                    exact={route.exact === undefined ? true : route.exact}
                                    path={route.path}
                                    requiredPermission={route?.requiredPermission}
                                    section={route?.section}
                                    component={route.component}
                                    key={route.path}
                                    withoutPromotion={route.withoutPromotion ? route.withoutPromotion : false}
                                />
                            ))}
                            <Route component={NotFound} />
                        </Switch>
                    </AppBootstrap>
                </ErrorBoundary>
            </Router>
        </GlobalStore>
    );
};
export default AppRouter;
