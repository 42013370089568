export const ROLES = {
    reader: 'ROLE_READER',
    writer: 'ROLE_WRITER',
    writerAudit: 'ROLE_WRITER_AUDIT',
    maintenance: 'ROLE_MAINTENANCE',
    admin: 'ROLE_ADMIN',
    dumpMaster: 'ROLE_DUMPMASTER'
};

export const ACTIONS = {
    canView: 'canView',
    canViewDetail: 'canViewDetail',
    canCreate: 'canCreate',
    canComplete: 'canComplete',
    canAccept: 'canAccept' // for carrier
};

export const BASE_PERMISSIONS = {
    enterprise: [],
    profile: [ACTIONS.canView, ACTIONS.canCreate],
    promotion: [],
    promotionCompare: [],
    newPromotionRequest: [],
    statusByLer: [],
    documentation: [],
    traceability: [],
    costs: [],
    activities: [],
    siteChecking: [],
    reports: [],
    configuration: [],
    wasteConfiguration: [],
    wasteManagerTraceability: [],
    changelog: [],
    dashboard: [],
    impact: [],
    automaticLecture: [],
    automaticReadingRetry: [],
    promotionSettings: [],
    users: [],
    admin: [],
    egrandpgr: [],
    tags: [ACTIONS.canView],
    globalSettings: [],
    projectSettings: [],
    kpiGoals: [],
    notificationSettings: [],
    comparative: []
};

const READER_PERMISIONS = {
    ...BASE_PERMISSIONS,
    enterprise: [ACTIONS.canView],
    promotion: [ACTIONS.canView],
    documentation: [ACTIONS.canView],
    traceability: [ACTIONS.canView],
    costs: [ACTIONS.canView],
    activities: [ACTIONS.canView],
    siteChecking: [ACTIONS.canView],
    users: [ACTIONS.canView],
    reports: [],
    dashboard: [ACTIONS.canView],
    impact: [ACTIONS.canView],
    tags: [],
    egrandpgr: [ACTIONS.canView],
    globalSettings: [],
    projectSettings: [],
    kpiGoals: [ACTIONS.canView]
};

const WRITER_PERMISIONS = {
    ...BASE_PERMISSIONS,
    documentation: [ACTIONS.canCreate],
    traceability: [ACTIONS.canCreate],
    costs: [ACTIONS.canCreate],
    users: [ACTIONS.canView],
    activities: [ACTIONS.canCreate],
    configuration: [ACTIONS.canView, ACTIONS.canCreate],
    dashboard: [ACTIONS.canView],
    tags: [ACTIONS.canView, ACTIONS.canCreate],
    egrandpgr: [ACTIONS.canView, ACTIONS.canCreate],
    globalSettings: [ACTIONS.canView, ACTIONS.canCreate],
    projectSettings: [ACTIONS.canView, ACTIONS.canCreate],
    kpiGoals: [ACTIONS.canView]
};

const WRITER_AUDIT_PERMISIONS = {
    ...BASE_PERMISSIONS,
    enterprise: [ACTIONS.canView],
    siteChecking: [ACTIONS.canView, ACTIONS.canComplete, ACTIONS.canViewDetail],
    promotion: [ACTIONS.canView],
    tags: [],
    globalSettings: [],
    projectSettings: [],
    kpiGoals: [ACTIONS.canView]
};

const MAINTENANCE_PERMISSIONS = {
    ...BASE_PERMISSIONS,
    newPromotionRequest: [ACTIONS.canCreate],
    configuration: [ACTIONS.canView, ACTIONS.canCreate],
    promotionSettings: [ACTIONS.canView, ACTIONS.canCreate],
    users: [ACTIONS.canView, ACTIONS.canCreate],
    dashboard: [ACTIONS.canView],
    tags: [ACTIONS.canView, ACTIONS.canCreate],
    egrandpgr: [ACTIONS.canView, ACTIONS.canCreate],
    globalSettings: [ACTIONS.canView, ACTIONS.canCreate],
    projectSettings: [ACTIONS.canView, ACTIONS.canCreate],
    kpiGoals: [ACTIONS.canView]
};

const ADMIN_PERMISSIONS = {
    ...BASE_PERMISSIONS,
    enterprise: [ACTIONS.canView, ACTIONS.canCreate],
    promotion: [ACTIONS.canView, ACTIONS.canCreate],
    promotionCompare: [ACTIONS.canView],
    statusByLer: [ACTIONS.canView, ACTIONS.canCreate],
    documentation: [ACTIONS.canView, ACTIONS.canCreate],
    traceability: [ACTIONS.canView, ACTIONS.canCreate],
    costs: [ACTIONS.canView, ACTIONS.canCreate],
    activities: [ACTIONS.canView, ACTIONS.canCreate],
    siteChecking: [ACTIONS.canView, ACTIONS.canCreate, ACTIONS.canComplete, ACTIONS.canViewDetail],
    reports: [ACTIONS.canView, ACTIONS.canCreate],
    wasteConfiguration: [ACTIONS.canView, ACTIONS.canCreate],
    changelog: [ACTIONS.canView],
    dashboard: [ACTIONS.canView],
    impact: [ACTIONS.canView],
    admin: [ACTIONS.canView, ACTIONS.canCreate],
    promotionSettings: [ACTIONS.canView, ACTIONS.canCreate],
    users: [ACTIONS.canView, ACTIONS.canCreate],
    egrandpgr: [ACTIONS.canView, ACTIONS.canCreate],
    configuration: [ACTIONS.canView, ACTIONS.canCreate],
    /**
     * permissions from api/tracing/extractions/access?promotionId
     * @link src/modules/e2e/automatic-reading/domain/Permissions/AutomaticReadingPermissions.ts
     * */
    automaticLecture: [],
    automaticReadingRetry: [ACTIONS.canView],
    tags: [ACTIONS.canView, ACTIONS.canCreate],
    globalSettings: [ACTIONS.canView, ACTIONS.canCreate],
    projectSettings: [ACTIONS.canView, ACTIONS.canCreate],
    kpiGoals: [ACTIONS.canView, ACTIONS.canCreate],
    notificationSettings: [ACTIONS.canView, ACTIONS.canCreate],
    comparative: [ACTIONS.canView]
};

const DUMPMASTER_PERMISIONS = {
    ...BASE_PERMISSIONS,
    wasteManagerTraceability: [ACTIONS.canView, ACTIONS.canCreate]
};

export const PERMISSIONS = {
    [ROLES.reader]: READER_PERMISIONS,
    [ROLES.writer]: WRITER_PERMISIONS,
    [ROLES.writerAudit]: WRITER_AUDIT_PERMISIONS,
    [ROLES.maintenance]: MAINTENANCE_PERMISSIONS,
    [ROLES.admin]: ADMIN_PERMISSIONS,
    [ROLES.dumpMaster]: DUMPMASTER_PERMISIONS
};
