import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import './ComparativeExplanation.scss';
import { useTranslation } from 'react-i18next';

interface ComparativeExplanationProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

const ComparativeExplanation = (props: ComparativeExplanationProps) => {
    const [t] = useTranslation('comparative');
    return (
        <SimplePopover
            openModal={props.openModal}
            setOpenModal={props.setOpenModal}
            title={t('evaluation.explanation.title')}
            className="ComparativeExplanation"
        >
            <section>
                <p>{t('evaluation.explanation.text')}</p>

                <ul>
                    <li>
                        <span>{t('evaluation.explanation.protocol.level')}</span>{' '}
                        <b>{t('evaluation.explanation.protocol.title')}</b>: {t('evaluation.explanation.protocol.text')}
                    </li>
                    <li>
                        <span>{t('evaluation.explanation.execution.level')}</span>{' '}
                        <b>{t('evaluation.explanation.execution.title')}</b>:{' '}
                        {t('evaluation.explanation.execution.text')}
                        <ul>
                            <li>
                                <span>{t('evaluation.explanation.execution.valorization.level')}</span>{' '}
                                {t('evaluation.explanation.execution.valorization.title')}:{' '}
                                {t('evaluation.explanation.execution.valorization.text')}
                            </li>
                            <li>
                                <span>{t('evaluation.explanation.execution.dangerous.level')}</span>{' '}
                                {t('evaluation.explanation.execution.dangerous.title')}:{' '}
                                {t('evaluation.explanation.execution.dangerous.text')}
                            </li>
                            <li>
                                <span>{t('evaluation.explanation.execution.rsu.level')}</span>{' '}
                                {t('evaluation.explanation.execution.rsu.title')}:{' '}
                                {t('evaluation.explanation.execution.rsu.text')}
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>{t('evaluation.explanation.documentation.level')}</span>{' '}
                        <b>{t('evaluation.explanation.documentation.title')}</b>:{' '}
                        {t('evaluation.explanation.documentation.text')}
                        <ul>
                            <li>
                                <span>{t('evaluation.explanation.documentation.lastTraceability.level')}</span>{' '}
                                {t('evaluation.explanation.documentation.lastTraceability.text')}
                            </li>
                            <li>
                                <span>{t('evaluation.explanation.documentation.existsEgrOrPgr.level')}</span>{' '}
                                {t('evaluation.explanation.documentation.existsEgrOrPgr.text')}
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
        </SimplePopover>
    );
};

export default ComparativeExplanation;
