import { GridOptions } from 'ag-grid-community';
import { Link } from 'react-router-dom';

import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import ProgressBar from 'components/BasicComponents/Progress/ProgressBar/ProgressBar';
import EllipsisText from 'components/BasicComponents/Text/EllipsisText';
import KPIStatusIcon from 'features/KPIs/components/KPIStatusIcon';
import TagBadge from 'features/tags/components/TagBadge';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { AG_GRID_LOCALE_ES } from 'i18n/agGrid_ES';
import { ComparativePromotion, Status } from 'modules/comparative';
import { KPIStatus } from 'modules/promotion-kpis';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import { useAuth } from 'modules/auth';

type Options = GridOptions<ComparativePromotion>;

export type ObservationsInfo = {
    observations: ComparativePromotion['observations'];
    project: {
        id: ComparativePromotion['promotion']['id'];
        name: ComparativePromotion['promotion']['name'];
    };
};

const group = {};

/* Inspiration from: https://stackoverflow.com/a/51445435 */
const isFirstColumn = (cell) => {
    const { column } = cell;
    const { parent } = column;

    // maximum of 3 nested levels
    const firstColumnId =
        parent?.parent?.parent?.displayedChildren?.[0].displayedChildren?.[0].displayedChildren?.[0].colId ??
        parent?.parent?.displayedChildren?.[0].displayedChildren?.[0].colId ??
        parent?.displayedChildren?.[0].colId;

    return column.colId === firstColumnId;
};

const column = {
    sortable: true,
    filter: true,
    wrapHeaderText: true,
    cellStyle: (params) => {
        const cellType = params.colDef.cellDataType;
        let extraStyles = {};

        if (cellType === 'percentageScore') {
            extraStyles = { ...extraStyles, color: `hsl(${params.value}deg 100% 35%)`, fontWeight: 500 };
        }
        if (cellType === 'number' || cellType === 'percentage' || cellType === 'percentageScore') {
            extraStyles = { ...extraStyles, 'justify-content': 'flex-end' };
        }
        if (isFirstColumn(params)) {
            extraStyles = { ...extraStyles, 'border-left': '1px solid rgb(205, 200, 218)' };
        }

        return extraStyles;
    }
};

export function useComparativeGrid() {
    const [t] = useTranslation('comparative');
    const { format } = useDateFormatter();
    const [{ isCoCircular }] = useAuth();
    const [openExplanationModal, setOpenExplanationModal] = useState(false);
    const [openObservationsModal, setOpenObservationsModal] = useState(false);
    const [selectedObservationsInfo, setSelectedObservationsInfo] = useState<ObservationsInfo | undefined>(undefined);

    const options: Options = {
        localeText: AG_GRID_LOCALE_ES,
        rowModelType: 'clientSide',
        dataTypeDefinitions: {
            percentageScore: {
                extendsDataType: 'number',
                baseDataType: 'number',
                valueFormatter: ({ value }) => (value == null ? '' : `${Math.trunc(value)}%`)
            },
            percentage: {
                extendsDataType: 'number',
                baseDataType: 'number',
                valueFormatter: ({ value }) => (value == null ? '' : `${Math.trunc(value)}%`)
            },
            number: {
                extendsDataType: 'number',
                baseDataType: 'number',
                valueFormatter: ({ value }) => (value == null ? '' : `${currencyFormatDE(Math.trunc(value))}`)
            },
            dateType: {
                extendsDataType: 'dateString',
                baseDataType: 'dateString',
                valueFormatter: ({ value }) => (value == null ? '' : format(value, 'DD/MM/YY'))
            }
        },
        headerHeight: 40,
        groupHeaderHeight: 36,
        tooltipShowDelay: 500,
        columnDefs: [
            {
                ...group,
                headerName: t('promotion.title'),
                field: 'promotion',
                children: [
                    {
                        ...column,
                        field: 'promotion.name',
                        headerName: t('promotion.name'),
                        pinned: 'left',
                        floatingFilter: true,
                        cellRenderer: PromotionCell
                    },
                    {
                        ...column,
                        field: 'promotion.progress',
                        headerName: t('promotion.progress'),
                        pinned: 'left',
                        width: 180,
                        cellRenderer: (params) => (
                            <ProgressBar
                                displayPercentageText={true}
                                percentage={params?.value || 0}
                                style={{ width: '100%' }}
                            />
                        )
                    }
                ]
            },
            {
                ...group,
                field: 'evaluation',
                headerName: t('evaluation.title'),
                headerGroupComponent: () => (
                    <p className="Comparative__evaluationHeader">
                        <span>{t('evaluation.title')}</span>
                        <SmallButton
                            titulo={t('howItsCalculated')}
                            icon={<IconInfoCircle />}
                            iconPosition="right"
                            action={() => setOpenExplanationModal(true)}
                        />
                    </p>
                ),
                children: [
                    {
                        ...column,
                        field: 'evaluation.score',
                        cellDataType: 'percentageScore',
                        headerName: t('total'),
                        width: 120
                    },
                    {
                        ...column,
                        field: 'evaluation.protocol',
                        headerName: t('evaluation.protocol.title'),
                        children: [
                            {
                                ...column,
                                field: 'evaluation.protocol.score',
                                cellDataType: 'percentage',
                                headerName: t('total'),
                                width: 120
                            },
                            {
                                ...column,
                                field: 'evaluation.protocol.valorizable.score',
                                cellDataType: 'boolean',
                                cellRenderer: BooleanCell,
                                headerName: t('valorizable'),
                                columnGroupShow: 'open',
                                width: 130
                            },
                            {
                                ...column,
                                field: 'evaluation.protocol.dangerous.score',
                                cellDataType: 'boolean',
                                cellRenderer: BooleanCell,
                                headerName: t('dangerous'),
                                columnGroupShow: 'open',
                                width: 130
                            },
                            {
                                ...column,
                                field: 'evaluation.protocol.rsu.score',
                                cellDataType: 'boolean',
                                cellRenderer: BooleanCell,
                                headerName: t('rsu'),
                                columnGroupShow: 'open',
                                width: 130
                            }
                        ]
                    },
                    {
                        ...column,
                        field: 'evaluation.execution',
                        headerName: t('evaluation.execution.title'),
                        children: [
                            {
                                ...column,
                                field: 'evaluation.execution.score',
                                headerName: t('total'),
                                cellDataType: 'percentage',
                                width: 120
                            },
                            {
                                ...column,
                                field: 'evaluation.execution.valorization',
                                headerName: t('valorizable'),
                                cellDataType: 'percentage',
                                columnGroupShow: 'open',
                                children: [
                                    {
                                        ...column,
                                        field: 'evaluation.execution.valorization.score',
                                        headerName: t('total'),
                                        cellDataType: 'percentage',
                                        width: 130
                                    },
                                    {
                                        ...column,
                                        field: 'evaluation.execution.valorization.purity',
                                        headerName: t('evaluation.execution.purity'),
                                        cellDataType: 'percentage',
                                        columnGroupShow: 'open',
                                        width: 120
                                    },
                                    {
                                        ...column,
                                        field: 'evaluation.execution.valorization.signage',
                                        headerName: t('evaluation.execution.signage'),
                                        cellDataType: 'percentage',
                                        columnGroupShow: 'open',
                                        width: 120
                                    }
                                ]
                            },
                            {
                                ...column,
                                field: 'evaluation.execution.dangerous',
                                headerName: t('dangerous'),
                                cellDataType: 'percentage',
                                columnGroupShow: 'open',
                                children: [
                                    {
                                        ...column,
                                        field: 'evaluation.execution.dangerous.score',
                                        headerName: t('total'),
                                        cellDataType: 'percentage',
                                        width: 130
                                    },
                                    {
                                        ...column,
                                        field: 'evaluation.execution.dangerous.purity',
                                        headerName: t('evaluation.execution.purity'),
                                        cellDataType: 'percentage',
                                        columnGroupShow: 'open',
                                        width: 120
                                    },
                                    {
                                        ...column,
                                        field: 'evaluation.execution.dangerous.signage',
                                        headerName: t('evaluation.execution.signage'),
                                        cellDataType: 'percentage',
                                        columnGroupShow: 'open',
                                        width: 120
                                    },
                                    {
                                        ...column,
                                        field: 'evaluation.execution.dangerous.rainProtection',
                                        headerName: t('evaluation.execution.rainProtection'),
                                        cellDataType: 'percentage',
                                        columnGroupShow: 'open',
                                        width: 160
                                    },
                                    {
                                        ...column,
                                        field: 'evaluation.execution.dangerous.waterproofTrays',
                                        headerName: t('evaluation.execution.waterproofTrays'),
                                        cellDataType: 'percentage',
                                        columnGroupShow: 'open',
                                        width: 190
                                    }
                                ]
                            },
                            {
                                ...column,
                                field: 'evaluation.execution.rsu',
                                headerName: t('rsu'),
                                cellDataType: 'percentage',
                                columnGroupShow: 'open',
                                children: [
                                    {
                                        ...column,
                                        field: 'evaluation.execution.rsu.purity',
                                        headerName: t('evaluation.execution.purity'),
                                        cellDataType: 'percentage',
                                        maxWidth: 130
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        ...column,
                        field: 'evaluation.documentation',
                        headerName: t('evaluation.documentation.title'),
                        children: [
                            {
                                ...column,
                                field: 'evaluation.documentation.score',
                                headerName: t('total'),
                                cellDataType: 'percentage',
                                width: 155
                            },
                            {
                                ...column,
                                field: 'evaluation.documentation.lastTraceability',
                                headerName: t('evaluation.documentation.lastTraceability'),
                                columnGroupShow: 'open',
                                width: 130,
                                valueFormatter: ({ value }) => (value.daysPassed == null ? '' : value.daysPassed),
                                cellRenderer: ({ value }) =>
                                    StatusMetCell({
                                        text: value.daysPassed == null ? '' : `${value.daysPassed}`,
                                        met: value.status
                                    })
                            },
                            {
                                ...column,
                                field: 'evaluation.documentation.existsEgrOrPgr',
                                cellDataType: 'boolean',
                                cellRenderer: BooleanCell,
                                headerName: t('evaluation.documentation.existsEgrOrPgr'),
                                columnGroupShow: 'open',
                                width: 130
                            }
                        ]
                    }
                ]
            },
            {
                ...group,
                headerName: t('kpis.title'),
                field: 'kpis',
                children: [
                    {
                        ...column,
                        field: 'kpis.valorization',
                        headerName: t('kpis.valorization'),
                        width: 140,
                        // necessary to make filter work when using cellRenderer
                        valueFormatter: ({ value }) => (value.value == null ? '' : `${Math.trunc(value.value)}%`),
                        cellRenderer: ({ value }) =>
                            BooleanMetCell({
                                text: value.value == null ? '-' : `${Math.trunc(value.value)}%`,
                                met: value.value == null ? undefined : value.met
                            })
                    },
                    {
                        ...column,
                        field: 'kpis.mix',
                        headerName: t('kpis.mix'),
                        width: 140,
                        // necessary to make filter work when using cellRenderer
                        valueFormatter: ({ value }) => (value.value == null ? '' : `${Math.trunc(value.value)}%`),
                        cellRenderer: ({ value }) =>
                            BooleanMetCell({
                                text: value.value == null ? '-' : `${Math.trunc(value.value)}%`,
                                met: value.value == null ? undefined : value.met
                            })
                    },
                    {
                        ...column,
                        field: 'kpis.mandatoryFlows',
                        headerName: t('kpis.mandatoryFlows'),
                        width: 140,
                        // necessary to make filter work when using cellRenderer
                        valueFormatter: ({ value }) =>
                            value.total == null ? '' : `${value.segregated}/${value.total}`,
                        cellRenderer: ({ value }) =>
                            StatusMetCell({
                                text: value.total == null ? '' : `${value.segregated}/${value.total}`,
                                met: value.status
                            })
                    }
                ]
            },
            {
                ...group,
                headerName: 'Valoración CoCircular',
                field: 'observations',
                children: [
                    {
                        ...column,
                        field: 'observations.text',
                        headerName: 'Comentarios',
                        width: 400,
                        cellRenderer: (params) =>
                            ObservationsCell({
                                text: params.value,
                                isCoCircular,
                                action: () => {
                                    setSelectedObservationsInfo({
                                        observations: params?.data?.observations,
                                        project: {
                                            id: params?.data?.promotion?.id,
                                            name: params?.data?.promotion?.name
                                        }
                                    });
                                    setOpenObservationsModal(true);
                                }
                            })
                    },
                    {
                        ...column,
                        field: 'observations.date',
                        cellDataType: 'dateType',
                        headerName: 'Fecha',
                        width: 100
                    },
                    {
                        ...column,
                        field: 'observations.user',
                        headerName: 'Autor/a',
                        width: 120
                    }
                ]
            },
            {
                ...group,
                headerName: t('generatedWaste.title'),
                field: 'generatedWaste',
                children: [
                    {
                        ...column,
                        field: 'generatedWaste.fgr',
                        cellDataType: 'number',
                        headerName: t('generatedWaste.fgr'),
                        width: 165
                    },
                    {
                        ...column,
                        field: 'generatedWaste.egrOrPgrVsReal',
                        cellDataType: 'percentage',
                        headerName: t('generatedWaste.egrOrPgrVsReal'),
                        headerTooltip: t('generatedVsEgrOrPgrExplanation'),
                        width: 165
                    },
                    {
                        ...column,
                        field: 'generatedWaste.totalTraceabilities',
                        headerName: t('generatedWaste.totalTraceabilities.title'),
                        children: [
                            {
                                ...column,
                                field: 'generatedWaste.totalTraceabilities.total',
                                headerName: t('total'),
                                cellDataType: 'number',
                                width: 150
                            },
                            {
                                ...column,
                                field: 'generatedWaste.totalTraceabilities.valorization',
                                headerName: t('valorizable'),
                                cellDataType: 'number',
                                columnGroupShow: 'open',
                                width: 140
                            },
                            {
                                ...column,
                                field: 'generatedWaste.totalTraceabilities.dangerous',
                                headerName: t('dangerous'),
                                cellDataType: 'number',
                                columnGroupShow: 'open',
                                width: 140
                            },
                            {
                                ...column,
                                field: 'generatedWaste.totalTraceabilities.land',
                                headerName: t('lands'),
                                cellDataType: 'number',
                                columnGroupShow: 'open',
                                width: 140
                            }
                        ]
                    }
                ]
            },
            {
                ...group,
                headerName: t('promotion.other'),
                field: 'promotion',
                children: [
                    {
                        ...column,
                        field: 'promotion.owner',
                        headerName: t('promotion.owner'),
                        floatingFilter: true
                    },
                    {
                        ...column,
                        field: 'promotion.producer',
                        headerName: t('promotion.producer'),
                        floatingFilter: true
                    },
                    {
                        ...column,
                        field: 'promotion.managers',
                        headerName: t('promotion.managers'),
                        floatingFilter: true,
                        cellRenderer: ArrayCell,
                        filterValueGetter: (params) => params.data?.promotion.managers.map((m) => m.name).join(' ')
                    },
                    {
                        ...column,
                        field: 'promotion.tags',
                        cellDataType: 'object',
                        headerName: t('promotion.tags'),
                        floatingFilter: true,
                        cellRenderer: TagsCell,
                        valueFormatter: (params) => params.value.map((tag: any) => tag.name).join(', ')
                    },
                    {
                        ...column,
                        field: 'promotion.nima',
                        headerName: t('promotion.nima'),
                        maxWidth: 140
                    },
                    {
                        ...column,
                        field: 'promotion.pem',
                        headerName: t('promotion.pem'),
                        cellDataType: 'number',
                        maxWidth: 140
                    },
                    {
                        ...column,
                        field: 'promotion.surface',
                        headerName: t('promotion.surface'),
                        cellDataType: 'number',
                        width: 150
                    },
                    {
                        ...column,
                        field: 'promotion.type',
                        headerName: t('promotion.type'),
                        floatingFilter: true
                    },
                    {
                        ...column,
                        field: 'promotion.startDate',
                        headerName: t('promotion.startDate'),
                        cellDataType: 'dateType',
                        maxWidth: 140
                    },
                    {
                        ...column,
                        field: 'promotion.endDate',
                        headerName: t('promotion.endDate'),
                        cellDataType: 'dateType',
                        maxWidth: 140
                    },
                    {
                        ...column,
                        field: 'promotion.province',
                        headerName: t('promotion.province'),
                        floatingFilter: true
                    },
                    {
                        ...column,
                        field: 'promotion.town',
                        headerName: t('promotion.town'),
                        floatingFilter: true
                    },
                    {
                        ...column,
                        field: 'promotion.cp',
                        headerName: t('promotion.cp'),
                        floatingFilter: true,
                        maxWidth: 120
                    },
                    {
                        ...column,
                        field: 'promotion.address',
                        headerName: t('promotion.address'),
                        floatingFilter: true,
                        width: 500 // AG Grid croppes out the text for some unknown reason... it's the last element so it doesn't matter if it has 500px
                    }
                ]
            }
        ]
    };

    return {
        options,
        openExplanationModal,
        setOpenExplanationModal,
        openObservationsModal,
        setOpenObservationsModal,
        selectedObservationsInfo
    };
}

const TagsCell = (params) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                // flexWrap: 'wrap',
                height: '100%',
                gap: '0.4rem'
            }}
        >
            {params.value.map((tag: any, index) => (
                <TagBadge name={tag.name} color={tag.color} key={index} />
            ))}
        </div>
    );
};

const PromotionCell = (params) => {
    return (
        <Link
            className="link"
            to={`/promotion/${params.data.promotion.id}`}
            // target="_blank"
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%'
            }}
        >
            {params.value}
        </Link>
    );
};

const BooleanCell = (params) => {
    return (
        <p className="Comparative__boolean">
            {params.value === null && '-'}
            {!!params.value ? <IconCheck /> : <IconX />}
        </p>
    );
};

const BooleanMetCell = ({ text, met }: { text: string; met: boolean | null }) => {
    const status = met === true ? 'SUCCESS' : met === false ? 'DANGER' : 'INDETERMINATE';
    const color = met === true ? 'green' : met === false ? 'red' : '';

    return getMetCellHtml(status, color, text);
};

const StatusMetCell = ({ text, met }: { text: string; met: Status | null }) => {
    const status =
        met === 'OK' ? 'SUCCESS' : met === 'WARNING' ? 'WARNING' : met === 'FAILED' ? 'DANGER' : 'INDETERMINATE';
    const color = met === 'OK' ? 'green' : met === 'WARNING' ? 'yellow' : met === 'FAILED' ? 'red' : '';

    return getMetCellHtml(status, color, text);
};

const getMetCellHtml = (status: string, color: string, text: string) => {
    return (
        <p className={`Comparative__kpi --${color}`}>
            <span>{text}</span>
            <KPIStatusIcon status={KPIStatus[status]} />
        </p>
    );
};

const ArrayCell = (params) => {
    return params.value.map((m) => m.name).join(', ');
};

const ObservationsCell = (props: { isCoCircular: boolean; text?: string; action: () => void }) => {
    return (
        <p className="Comparative__viewMoreObservations">
            <EllipsisText>{props.text || ''}</EllipsisText>

            {props.text ? (
                <SmallButton titulo={'Ver más'} action={() => props.action()} />
            ) : (
                <>{props.isCoCircular && <SmallButton titulo={'Añadir'} action={() => props.action()} />}</>
            )}
        </p>
    );
};
