import axios, { AxiosError } from 'axios';
import { logger } from 'logger';
import { toastHandler, methodsToast } from './toastHandler.helper';
import { getToken } from './getToken.helper';
import { dispatchLogout } from './LogoutHandler';

function authRequestInterceptor(config) {
    const token = getToken();
    if (token) {
        config.headers.Authorization = token;
    }
    config.headers.Accept = 'application/json';
    return config;
}

const fetcher = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

fetcher.interceptors.request.use(authRequestInterceptor);
fetcher.interceptors.response.use(
    (response) => {
        if (response && response.config.method && methodsToast.includes(response.config.method)) {
            toastHandler(response);
        }
        return response;
    },
    (axiosError: AxiosError) => {
        if (
            axiosError?.response &&
            axiosError.response.config.method &&
            methodsToast.includes(axiosError.response.config.method)
        ) {
            toastHandler(axiosError.response);
        }
        if (axiosError.response?.status === 401) {
            dispatchLogout();
        }

        const message = axiosError.message;
        const stack = axiosError.response?.data || axiosError.stack;

        console.error(message, stack);

        const shouldSendLog =
            !axiosError.response || axiosError.response.status >= 400 || axiosError.response.status < 200;

        if (shouldSendLog) {
            const extra = {
                message,
                data: axiosError.response?.data,
                status: axiosError.response?.status,
                headers: axiosError.response?.headers,
                url: axiosError.config.url,
                params: axiosError.config.params,
                request: axiosError.config
            };
            logger.send(axiosError, extra);
        }

        return Promise.reject(axiosError);
    }
);

export default fetcher;
